import { default as transactionspryigttkUNMeta } from "/app/pages/[organizationId]/api/transactions.vue?macro=true";
import { default as dashboardz8ACBfBhFOMeta } from "/app/pages/[organizationId]/dashboard.vue?macro=true";
import { default as contactL8oYWjPG8WMeta } from "/app/pages/[organizationId]/help/contact.vue?macro=true";
import { default as index3fQSuE0gMIMeta } from "/app/pages/[organizationId]/help/index.vue?macro=true";
import { default as _91contentName_93GUv0pFIH3fMeta } from "/app/pages/[organizationId]/help/questions/[contentName].vue?macro=true";
import { default as contactsN9HzjHum8BMeta } from "/app/pages/[organizationId]/organization/contacts.vue?macro=true";
import { default as detailsOdgeyzIRceMeta } from "/app/pages/[organizationId]/organization/details.vue?macro=true";
import { default as indextPrN1DKHaWMeta } from "/app/pages/[organizationId]/settings/organization-and-users/user-list/index.vue?macro=true";
import { default as invoicesmE5KUbnezYMeta } from "/app/pages/[organizationId]/settings/plan-and-billing/invoices.vue?macro=true";
import { default as indexUoVhFhqmrwMeta } from "/app/pages/[organizationId]/settings/plan-and-billing/overview/index.vue?macro=true";
import { default as historyZVpQWt94vQMeta } from "/app/pages/[organizationId]/settings/transactions/history.vue?macro=true";
import { default as api_45keyBLJ3UJFk6BMeta } from "/app/pages/[organizationId]/settings/user/api-key.vue?macro=true";
import { default as basic_45infodJ89lYsFg1Meta } from "/app/pages/[organizationId]/settings/user/basic-info.vue?macro=true";
import { default as _91emailTemplateId_93ATQqSiZj4zMeta } from "/app/pages/[organizationId]/template/email/import-html/edit/[emailTemplateId].vue?macro=true";
import { default as index7v8HFqkpUsMeta } from "/app/pages/[organizationId]/template/email/import-html/index.vue?macro=true";
import { default as indexekis993uNsMeta } from "/app/pages/[organizationId]/template/email/index.vue?macro=true";
import { default as smsGCxQoUUFJcMeta } from "/app/pages/[organizationId]/template/sms.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as _91id_93xzd1NyLSDuMeta } from "/app/pages/auth/new-password/[passwordToken]/[id].vue?macro=true";
import { default as recoveryIXjTrEkYnmMeta } from "/app/pages/auth/recovery.vue?macro=true";
import { default as _91inviteId_93gbbpV6EfXgMeta } from "/app/pages/auth/register/invite/[inviteId].vue?macro=true";
import { default as select_45organization7NEdC6GsoPMeta } from "/app/pages/select-organization.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: "organizationId-api-transactions",
    path: "/:organizationId()/api/transactions",
    component: () => import("/app/pages/[organizationId]/api/transactions.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-dashboard",
    path: "/:organizationId()/dashboard",
    component: () => import("/app/pages/[organizationId]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-help-contact",
    path: "/:organizationId()/help/contact",
    component: () => import("/app/pages/[organizationId]/help/contact.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-help",
    path: "/:organizationId()/help",
    component: () => import("/app/pages/[organizationId]/help/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-help-questions-contentName",
    path: "/:organizationId()/help/questions/:contentName()",
    component: () => import("/app/pages/[organizationId]/help/questions/[contentName].vue").then(m => m.default || m)
  },
  {
    name: "organizationId-organization-contacts",
    path: "/:organizationId()/organization/contacts",
    component: () => import("/app/pages/[organizationId]/organization/contacts.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-organization-details",
    path: "/:organizationId()/organization/details",
    component: () => import("/app/pages/[organizationId]/organization/details.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-settings-organization-and-users-user-list",
    path: "/:organizationId()/settings/organization-and-users/user-list",
    meta: indextPrN1DKHaWMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/organization-and-users/user-list/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-settings-plan-and-billing-invoices",
    path: "/:organizationId()/settings/plan-and-billing/invoices",
    meta: invoicesmE5KUbnezYMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/plan-and-billing/invoices.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-settings-plan-and-billing-overview",
    path: "/:organizationId()/settings/plan-and-billing/overview",
    meta: indexUoVhFhqmrwMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/plan-and-billing/overview/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-settings-transactions-history",
    path: "/:organizationId()/settings/transactions/history",
    meta: historyZVpQWt94vQMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/transactions/history.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-settings-user-api-key",
    path: "/:organizationId()/settings/user/api-key",
    meta: api_45keyBLJ3UJFk6BMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/user/api-key.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-settings-user-basic-info",
    path: "/:organizationId()/settings/user/basic-info",
    meta: basic_45infodJ89lYsFg1Meta || {},
    component: () => import("/app/pages/[organizationId]/settings/user/basic-info.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-template-email-import-html-edit-emailTemplateId",
    path: "/:organizationId()/template/email/import-html/edit/:emailTemplateId()",
    component: () => import("/app/pages/[organizationId]/template/email/import-html/edit/[emailTemplateId].vue").then(m => m.default || m)
  },
  {
    name: "organizationId-template-email-import-html",
    path: "/:organizationId()/template/email/import-html",
    meta: index7v8HFqkpUsMeta || {},
    component: () => import("/app/pages/[organizationId]/template/email/import-html/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-template-email",
    path: "/:organizationId()/template/email",
    component: () => import("/app/pages/[organizationId]/template/email/index.vue").then(m => m.default || m)
  },
  {
    name: "organizationId-template-sms",
    path: "/:organizationId()/template/sms",
    component: () => import("/app/pages/[organizationId]/template/sms.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-new-password-passwordToken-id",
    path: "/auth/new-password/:passwordToken()/:id()",
    meta: _91id_93xzd1NyLSDuMeta || {},
    component: () => import("/app/pages/auth/new-password/[passwordToken]/[id].vue").then(m => m.default || m)
  },
  {
    name: "auth-recovery",
    path: "/auth/recovery",
    meta: recoveryIXjTrEkYnmMeta || {},
    component: () => import("/app/pages/auth/recovery.vue").then(m => m.default || m)
  },
  {
    name: "auth-register-invite-inviteId",
    path: "/auth/register/invite/:inviteId()",
    meta: _91inviteId_93gbbpV6EfXgMeta || {},
    component: () => import("/app/pages/auth/register/invite/[inviteId].vue").then(m => m.default || m)
  },
  {
    name: "select-organization",
    path: "/select-organization",
    component: () => import("/app/pages/select-organization.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]