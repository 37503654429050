<script setup lang="ts">
import { EToastType, useToastStore, type IToast } from '~/stores/toast'

const toastStore = useToastStore()
const { t } = useI18n()

const toastTypeConfig = {
  [EToastType.SUCCESS]: {
    color: 'success',
    title: t('toast.title.default.success'),
  },
  [EToastType.DANGER]: {
    color: 'error',
    title: t('toast.title.default.danger'),
  },
  [EToastType.WARNING]: {
    color: 'warning',
    title: t('toast.title.default.warning'),
  },
}

const getToastColorByType = (toastType: EToastType) =>
  toastTypeConfig[toastType]?.color || undefined

const getToastTitleDefault = (toast: IToast) =>
  toast.title || toastTypeConfig[toast.type]?.title

// Remove toast da store sem perder animação do coreui
const handleToastClose = (toastId: string) =>
  setTimeout(() => toastStore.removeToast(toastId), 1000)
</script>

<template>
  <div>
    <CToaster class="tw-p-3" placement="bottom-end">
      <CToast
        v-for="toast in toastStore.toasts"
        :key="toast.id"
        :color="getToastColorByType(toast.type)"
        visible
        @close="handleToastClose(toast.id)"
      >
        <CToastHeader close-button>
          <span class="tw-me-auto tw-font-bold tw-text-base tw-up">{{
            getToastTitleDefault(toast)
          }}</span>
        </CToastHeader>
        <CToastBody>
          <span class="tw-text-base">{{ toast.content }}</span>
        </CToastBody>
      </CToast>
    </CToaster>
  </div>
</template>
