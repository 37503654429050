import { defineNuxtPlugin } from '#app'
import type { VueI18n } from 'vue-i18n'

enum EErrorCode {
  PHONE_NUMBER_DUPLICATED = 10024,
  NAME_EMPTY = 10013,
  TEMPLATE_NAME_ALREADY_EXISTS = 1007011,
  TEMPLATE_UNSUBSCRIBE_TAG_NOT_FOUND = 1007032,
  WORKFLOW_INVALID_STATUS_UPDATE = 200017,
  ORGANIZATION_DOES_NOT_HAVE_AN_ACTIVE_PLAN = 160076,
  AUDIENCE_SCHEMA_NOT_FOUND = 1000,
}

function handleErrorCode(i18n: VueI18n, errorCode?: EErrorCode) {
  // * Checa se errorCode e tradução existem
  if (errorCode && i18n.te(`error.codes.${errorCode}`))
    return i18n.t(`error.codes.${errorCode}`)

  return i18n.t('error.codes.DEFAULT')
}

interface IErrorResponse {
  response: {
    data?: {
      errorCode?: number
    }
    _data?: {
      errorCode?: number
    }
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const i18nInstance = nuxtApp.$i18n as VueI18n
  return {
    provide: {
      errorCodeHandler: (error: IErrorResponse) => {
        // * * Backend pode retornar error?.response?.data ou error?.response?._data, por isso a tratativa abaixo
        const errorCode =
          error?.response?.data?.errorCode || error?.response?._data?.errorCode

        return handleErrorCode(i18nInstance, errorCode)
      },
    },
  }
})
