<!-- * * Inspirado em https://dev.to/cloudx/reusable-dynamic-modal-on-vue-3-1k56 -->

<template>
  <div>
    <CModal
      :visible="modalStore.isOpen"
      v-bind="modalStore.CModalProps"
      @close="modalStore.close"
    >
      <CModalHeader>
        <CModalTitle class="tw-text-2xl">{{ modalStore.title }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <component :is="modalStore.view" />
      </CModalBody>
      <CModalFooter class="tw-mt-auto">
        <div class="tw-flex tw-gap-1">
          <!-- * * Renderiza todas actions e passa o model payload como param -->
          <CLoadingButton
            v-for="action in modalStore.actions"
            :key="action.id"
            v-bind="action.cLoadingButtonProps"
            @click="action.callback"
          >
            {{ action.label }}
          </CLoadingButton>
        </div>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script lang="ts" setup>
const modalStore = useModalStore()
</script>

<style></style>
