import { defineStore } from 'pinia'
import { getLocaleByPriority } from '~/configs/i18n.config'

export enum SUPPORTED_LANGUAGE_OPTIONS {
  English = 'en-US',
  Portuguese = 'pt-PT',
}

export enum SUPPORTED_LANGUAGE_OPTIONS_NUM {
  English = 1,
  Portuguese,
}

export const useLanguageStore = defineStore('language', () => {
  const i18n = useI18n()

  const supportedLanguages: SUPPORTED_LANGUAGE_OPTIONS[] = Object.values(
    SUPPORTED_LANGUAGE_OPTIONS,
  )

  const userLanguage = ref<SUPPORTED_LANGUAGE_OPTIONS>(getLocaleByPriority())

  const numericToStringLanguage = (
    language: SUPPORTED_LANGUAGE_OPTIONS_NUM,
  ): SUPPORTED_LANGUAGE_OPTIONS => {
    switch (language) {
      case SUPPORTED_LANGUAGE_OPTIONS_NUM.English:
        return SUPPORTED_LANGUAGE_OPTIONS.English
      case SUPPORTED_LANGUAGE_OPTIONS_NUM.Portuguese:
        return SUPPORTED_LANGUAGE_OPTIONS.Portuguese
      default:
        throw new Error('Unsupported language option')
    }
  }

  const setUserLanguage = (
    language: SUPPORTED_LANGUAGE_OPTIONS | SUPPORTED_LANGUAGE_OPTIONS_NUM,
  ) => {
    if (typeof language === 'number') {
      userLanguage.value = numericToStringLanguage(language)
    } else {
      userLanguage.value = language
    }

    setI18nLanguage(userLanguage.value)
  }

  const setI18nLanguage = (language: SUPPORTED_LANGUAGE_OPTIONS) => {
    i18n.setLocale(language)
  }

  return {
    userLanguage,
    supportedLanguages,
    setUserLanguage,
  }
})
