import { translationsService } from '~/services/nuxt'
import { defineI18nConfig } from '#imports'

export const getLocaleByPriority = (): SUPPORTED_LANGUAGE_OPTIONS => {
  const userData = JSON.parse(localStorage.getItem('userData') || '{}')

  let userLanguage: SUPPORTED_LANGUAGE_OPTIONS =
    SUPPORTED_LANGUAGE_OPTIONS.English

  switch (userData.language) {
    case 1:
      userLanguage = SUPPORTED_LANGUAGE_OPTIONS.English
      break
    case 2:
      userLanguage = SUPPORTED_LANGUAGE_OPTIONS.Portuguese
      break
  }

  if (userLanguage) return userLanguage

  const navigatorLanguage = useBrowserLocale()
  const availableLanguages = Object.values(SUPPORTED_LANGUAGE_OPTIONS)
  const matchedNavigatorLanguage = availableLanguages.find(
    (language) => language === navigatorLanguage,
  )

  return matchedNavigatorLanguage || SUPPORTED_LANGUAGE_OPTIONS.English
}

// TODO: Lidar com tradução via .env VUE_APP_I18N_LOCALE e VUE_APP_I18N_FALLBACK_LOCALE
export default defineI18nConfig(async () => {
  const localeByPrority = getLocaleByPriority()

  return {
    locale: localeByPrority,
    fallbackLocale: localeByPrority,
    messages: await translationsService.getMessages(),
    silentTranslationWarn: process.env.NODE_ENV === 'production',
  }
})
