import { defineNuxtRouteMiddleware } from '#app'

const handleHomePath = (isUserAuthenticated: boolean) => {
  const routeName = isUserAuthenticated ? 'select-organization' : 'auth-login'
  return navigateTo({ name: routeName })
}

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return

  const authStore = useAuthStore()
  const publicRouteNames = [
    'auth-login',
    'auth-register-invite-inviteId',
    'auth-recovery',
    'auth-new-password-passwordToken-id',
  ]

  const isAuthenticated = authStore.isUserAuthenticated
  const isHomePath = to.path === '/'
  const isPublicRoute = to.name && publicRouteNames.includes(to.name.toString())
  const isProtectedRoute = to.name && !isPublicRoute

  if (isHomePath) {
    return handleHomePath(isAuthenticated)
  }

  if (isAuthenticated && isPublicRoute && to.name !== 'select-organization') {
    return navigateTo({ name: 'select-organization' })
  }

  if (!isAuthenticated && isProtectedRoute && to.name !== 'auth-login') {
    return navigateTo({ name: 'auth-login' })
  }
})
