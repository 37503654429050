import { useUniqueId } from '@coreui/vue-pro'
import { defineStore } from 'pinia'

export enum EToastType {
  SUCCESS,
  DANGER,
  WARNING,
}

export interface IToastProps {
  content: string
  type: EToastType
  title?: string
}

export interface IToast extends IToastProps {
  id: string
}

export const useToastStore = defineStore('toast', () => {
  const toasts = reactive<IToast[]>([])

  const createToast = (toastProps: IToastProps) => {
    const id = useUniqueId().getUID()
    toasts.push({ id, ...toastProps })
  }

  const removeToast = (toastId: string) => {
    const index = toasts.findIndex((toast) => toast.id === toastId)
    if (index >= 0 && index < toasts.length) {
      toasts.splice(index, 1)
    }
  }

  const hasToast = computed(() => toasts.length > 0)

  return { toasts, createToast, removeToast, hasToast }
})
