<script setup lang="ts">
import * as icons from '@coreui/icons'
const nuxtApp = useNuxtApp()
// * Handler para resolver warning: "inject 'icons' not found" causado pelo comportamento interno do coreui
nuxtApp.vueApp.provide('icons', icons)
</script>

<template>
  <NuxtLayout class="tw-font-sans">
    <NuxtPage />
    <TheToast />
    <TheModal />
  </NuxtLayout>
</template>
